h3 {
    margin-top: 40px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding-bottom: 0px;
}

p {
    margin-left: 20px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin-right: 20px;
}